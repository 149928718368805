import React, { useEffect, useState } from 'react';
import Logo from "../images/logo-d.png"
const Video = ({ isVideoOpen: parentIsOpen = false, openVideoIndex: parentIsOpenIndex = -1, videoSrcURL, elementIndex, ...props }) => {
    // const [isVideoOpen, setIsVideoOpen] = useState(parentIsOpen)
    const [openVideoIndex, setOpenVideoIndex] = useState(parentIsOpenIndex)
    const [style, setStyle] = useState({
        opacity: 0,
        display: "none",
        inset: "auto",
    })
    useEffect(() => {
        setOpenVideoIndex(parentIsOpenIndex)
        // setIsVideoOpen(parentIsOpen);
        if (parentIsOpen && elementIndex === parentIsOpenIndex) {
            document.getElementById("videoIframe_"+parentIsOpenIndex).src += "&autoplay=1";
            document.body.className += ' overflow-hidden';
            setStyle({
                opacity: 1,
                display: "block",
                inset: 0,
            })
        }
    }, [parentIsOpenIndex, parentIsOpen, elementIndex]);
    const handleVideoIsClose = (e,openVideoIndex) => {
        var ysrc = document.getElementById("videoIframe_"+openVideoIndex).src;
        var newsrc = ysrc.replace("&autoplay=1", "");
        document.getElementById("videoIframe_"+openVideoIndex).src = newsrc;
        document.querySelector('body').classList.remove('overflow-hidden');
        // setIsVideoOpen(false);
        props.onChange(false)
        setStyle({
            opacity: 0,
            display: "none",
            inset: "auto",
        })
    }
    const innerStyle = {
        opacity: 0,
        display: "none",
        inset: "auto",
    }
    return (
        <>
            <div className="fixed z-50 block bg-black bg-opacity-90" style={(elementIndex === openVideoIndex) ? style : innerStyle}>
                <div className="container mx-auto flex flex-col h-full max-h-full">
                    <div className="flex justify-between items-center py-5">
                        <h1 className="inline-block w-10 h-10 relative">
                            <div className="logo text-white">
                                <img src={Logo} alt="website logo" />
                            </div>
                        </h1>
                        <button className="heading-s hover:opacity-75 focus:outline-none select-none highlight-none text-white" onClick={e=>handleVideoIsClose(e,openVideoIndex)}>Close</button>
                    </div>
                    <div className="flex flex-grow overflow-hidden" >
                        <div className="mx-auto w-full h-full flex items-center">
                            <div className="w-full h-full video-player-modal lg:relative lg:group lg:cursor-pointer">
                                <div className="w-full h-full">
                                        <iframe
                                            className="w-full h-full"
                                            id={`videoIframe_${elementIndex}`}
                                            src={`${videoSrcURL}?feature=oembed`}
                                            title={"videoTitle"}
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            frameBorder="0"
                                            webkitallowfullscreen="true"
                                            mozallowfullscreen="true"
                                            allowFullScreen
                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Video
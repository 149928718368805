import React from "react";
import { getImage, GatsbyImage } from "gatsby-plugin-image"
const TeamModel = ({
  handleClose,
  show,
  selectedPost,
  modelData,
}) => {
  return (
    <>
      {modelData &&
        modelData.map(({ node: post }, i) => (
          <div key={i} className={((selectedPost === i) && show) ? "model-block" : "model-hidden"}>
            <div
              data-id={i}
              className="model-overlay w-full h-full z-100">
              <div className="container mx-auto">
                <div className="model-wrapper w-full lg:w-3/5 mx-auto bg-white">
                  <div className="lg:pt-16 model-header bg-white  ms:bg-background-quinary">
                    <div className="mx-auto ms:flex flex-wrap items-center justify-center ms:pt-6-6 sm:pt-45 md:pt-55 lg:pt-0">
                      <div className="w-full text-center ms:text-left ms:w-2-10 pt-35 ms:pt-0 model-img bg-background-quinary ms:bg-transparent">
                        <GatsbyImage image={getImage(post.frontmatter.featured_image.image)} alt={`${post.frontmatter.featured_image.alt} | Decodeup Team`} className="mx-auto w-2-10 md:w-auto h-full object-cover mx-auto lg:mx-0" />
                      </div>
                      <div className="model-name ms:pl-5 1md:pl-45 lg:pl-56-1 lg:pt-0 pt-4-2  w-full ms:w-auto text-center ms:text-left">
                        <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-dark-blue ms:text-term-primary">{post.frontmatter.title}</h2>
                        <p className="text-center md:text-left font-worksans-normal text-p4 lg:text-p3 text-cyan ms:text-term-ternary">{post.frontmatter.position_inCompany} </p>
                      </div>
                    </div>
                    <div className="mt-2-3 mr-3-1 absolute top-0 right-0">
                      <button onClick={handleClose} className="focus:outline-none popup-close-btn">
                        <svg
                          width="30"
                          height="30"
                          viewBox="0 0 30 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M25.4559 8.48525L8.48535 25.4558"
                            stroke="#272735"
                          />
                          <path
                            d="M25.4559 25.4559L8.48535 8.48535"
                            stroke="#272735"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="py-6-1 lg:py-16 px-6 sm:px-10 lg:px-0  bg-white">
                    <div className="model-body w-full lg:w-3/4 mx-auto">
                      <div
                        className="text-p4 lg:text-p2 font-worksans-normal text-dark-blue"
                        dangerouslySetInnerHTML={{ __html: post.html }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
    </>
  );
};
export default TeamModel;

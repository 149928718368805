
import React, { useState } from "react"
import { graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown"

//components
import Layout from "../components/layout";
import Seo from "../components/seo";
import TeamModel from "../components/team-model";
import StayLoop from "../components/Home/stayloop";
import Events from "../components/events"
import Video from "../components/video"


const OurTeam = (props) => {
  const pageInfo = props.data.pageData;
  const SEOdata = props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang

  const { edges: teamMemberData } = props.data.teamMembers
  const [showModel, setShowModel] = useState(false)
  const [isSelectedModel, setIsSelectedModel] = useState(-1)
  const [isVideoOpen, setIsVideoOpen] = useState(false)
  const [openVideoIndex, setOpenVideoIndex] = useState(-1)
  const handleVideoIsOpen = (event, index) => {
    setOpenVideoIndex(index)
    setIsVideoOpen(true);
  }
  function handleVideoIsClose(isopenVideoState) {
    setIsVideoOpen(isopenVideoState);
  }
  const showModal = (event, index) => {
    setShowModel(true)
    setIsSelectedModel(index)
    document.body.classList.add("model-open");
  }
  const hideModal = () => {
    setShowModel(false)
    // document.body.classList.remove("model-open");
    setTimeout(() =>
    {
       document.body.classList.remove("model-open")
    },500)
  }
  return (
    <>
      <Layout>
        <Seo title={SEOdata ? SEOdata.seo_title : "Our-team"}
        description={SEOdata ? SEOdata.seo_description : ""}
        keywords={SEOdata ? SEOdata.seo_keyword : ""}
        image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
        single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
        currentPageUrlPath={props.location.pathname}
        image_format={SEOdata ? SEOdata.image_format : ""}
        image_height={SEOdata ? SEOdata.feature_image_height : ""}
        img_width={SEOdata ? SEOdata.img_width : ""}
        facebook_url={SEOdata ? SEOdata.facebook_url : ""}
        twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
        twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
        twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
        twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
        page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
        page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
        sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
        breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
        logo_schema={Schemadata ? Schemadata.logo_schema : ""}
        organization_schema={Schemadata ? Schemadata.organization_schema : ""}
        aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
        local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
        site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
        speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
        hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
        hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
        hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
        hreflang4={Hreflang ? Hreflang.hreflang_4 : ""} />

        {/* banner section */}

        <section className="mt-60 lg:mt-20">
          <BgImage
            image={getImage(pageInfo.frontmatter.banner_image_ot.image)}
            className="our-team-bg"
            alt={`${pageInfo.frontmatter.banner_image_ot.alt} | DecodeUp Team`}
          >
            <div className="job-banner-overlay relative h-full">
              <div className="container mx-auto text-center flex flex-wrap lg:block items-center h-full">
                <div className="mx-auto relative z-60 text-center relative z-60 w-full md:w-3/5 xl:w-2/5 lg:pt-87 lg:pb-1-50 w-full">
                  <p className="mb-1-1 lg:mb-2 font-poppings-bold text-rh6 lg:text-h6 text-cyan">
                    {pageInfo.frontmatter.sub_title}
                  </p>
                  <h2 className="text-rh2 sm:text-h3 lg:text-h2 font-poppings-bold text-white">
                    {pageInfo.frontmatter.title}
                  </h2>
                </div>
              </div>
            </div>
          </BgImage>
        </section>

        {/* meet our leads section */}

        <section className="pt-50 xl:pt-1-11">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-52 mx-auto w-full xl:w-2/3 text-center">
              <h3 className="mb-1 lg:mb-0 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                Meet Our Leads
              </h3>
              <MarkDown
                className="px-4 md:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary lead-contain"
                children={pageInfo.frontmatter.meet_our_leads.body1}
                allowDangerousHtml
              />
            </div>
            {/* <div className="w-full flex flex-wrap relative our-lead-bg lg:justify-between">
              {teamMemberData &&
                teamMemberData.map(({ node: post }, index) => (
                  <div
                    aria-hidden="true"
                    key={index}
                    onClick={event => showModal(event, index)}
                    data-id={index}
                    className=" pb-35 lg:pb-41 cursor-pointer w-full sm:w-1/2 md:w-1/3 lg:w-1/4 overflow-hidden"
                  >
                    <div className="relative out-team-sec pt-35 lg:pt-49 ">
                      <div className="xl:pl-10 2xl:pl-71 2xl:pr-10 relative mx-auto">
                        <div className="our-team-image mx-auto">
                          <GatsbyImage
                            image={getImage(
                              post.frontmatter.featured_image.image
                            )}
                            alt={`${post.frontmatter.featured_image.alt} | Decodeup Team`}
                          />
                        </div>
                        <div className="pt-4-2 lg:pt-6-1 w-full text-center">
                          <h3 className="font-worksans-bold text-rp1 lg:text-h3 text-term-primary">
                            {post.frontmatter.title}
                          </h3>
                          <p className=" font-worksans-normal text-p4 lg:text-p3 text-cyan">
                            {post.frontmatter.position_inCompany}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div> */}

            <div className="pb-35 grid grid-cols-1 mms:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-y-35 md:gap-y-41  our-lead-bg">
              {teamMemberData &&
                teamMemberData.map(({ node: post }, index) => (
                  <div
                    aria-hidden="true"
                    key={index}
                    onClick={event => showModal(event, index)}
                    data-id={index}
                    className=" w-full overflow-hidden"
                  >
                    <div className="relative out-team-sec pt-35 lg:pt-49 cursor-pointer ">
                      <div className=" relative mx-auto">
                        <div className="our-team-image mx-auto">
                          <GatsbyImage
                            image={getImage(
                              post.frontmatter.featured_image.image
                            )}
                            alt={post.frontmatter.featured_image.alt}
                          />
                        </div>
                        <div className="pt-4-2 lg:pt-6-1 w-full text-center max-w-220 mx-auto">
                          <h3 className="font-worksans-bold text-rp1 lg:text-h3 text-term-primary">
                            {post.frontmatter.title}
                          </h3>
                          <p className=" font-worksans-normal text-p4 lg:text-p3 text-cyan">
                            {post.frontmatter.position_inCompany}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            {/* open model section */}
            <div className="model">
              <TeamModel
                show={showModel}
                handleClose={hideModal}
                selectedPost={isSelectedModel}
                modelData={teamMemberData}
              />
            </div>
          </div>
        </section>

        {/* meet decodeup section */}
        <div className="container mx-auto">
          <Video
            isVideoOpen={isVideoOpen}
            openVideoIndex={openVideoIndex}
            onChange={handleVideoIsClose}
            videoSrcURL={pageInfo.frontmatter.meet_decodeup.video}
            elementIndex={1}
          />
        </div>

        <section className="pt-3-3 lg:pt-47">
          <div className="container mx-auto">
            <div className="pb-35 lg:pb-45 mx-auto w-full xl:w-2/3 text-center">
              <h3 className="pb-1 lg:pb-1-1 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                Meet DecodeUp
              </h3>
              <MarkDown
                className="px-4 md:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary meet-contains"
                children={pageInfo.frontmatter.meet_decodeup.body2}
                allowDangerousHtml
              />
            </div>
            <BgImage
              image={getImage(pageInfo.frontmatter.meet_decodeup.image)}
              className="meet-decodup-bg"
              alt={`${pageInfo.frontmatter.meet_decodeup.alt} | DecodeUp Team`}
            >
              <div
                className="relative meet-deecodeup-overlay w-full h-full"
                role="presentation"
                onKeyDown={event => handleVideoIsOpen(event, 1)}
                onClick={event => handleVideoIsOpen(event, 1)}
              >
                <span className="block absolute  w-full h-full flex flex-col inset-0 md:items-center md:justify-center group items-center justify-center">
                  <svg
                    className="w-52 md:w-65 lg:w-88 h-52 md:h-65 lg:h-88  cursor-pointer group-hover:opacity-75 transition-opacity duration-200 :m-0"
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 98 98"
                  >
                    <title>Group 3</title>
                    <circle fill="#fff" cx="49" cy="49" r="49" />
                    <path fill="#c4c4c4" d="M62.36,48.44,43.15,59.53V37.35Z" />
                  </svg>
                  <span className="pt-3 font-poppings-bold text-rh6 lg:text-h6 text-white text-center">
                    CLICK TO PLAY
                  </span>
                </span>
              </div>
            </BgImage>
          </div>
        </section>

        {/* events and celebration section */}
        <section className="pt-50 pb-85 lg:pt-1-13 lg:pb-1-17 overflow-hidden">
          <Events />
        </section>

        {/* stay in loop section */}
        <StayLoop />
      </Layout>
    </>
  )
}

export default OurTeam;

export const pageQuery = graphql`
query {
  teamMembers: allMarkdownRemark(
    sort: { order: ASC, fields: [frontmatter___date] }
    filter: {frontmatter: {templateKey: {eq: "our-team"}}}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            position_inCompany
            featured_image {
              alt
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
            date
          }
          html
        }
      }
    }
    pageData: markdownRemark(fileAbsolutePath: {regex: "/pages/our-team.md/"}) {
      frontmatter {
        title
        sub_title
        meet_decodeup {
          body2
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
          video
        }
        meet_our_leads {
          body1
        }
        banner_image_ot {
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
    awardpageData: allMarkdownRemark(
      filter: { fileAbsolutePath:  {regex: "/pages/our-team.md/"} }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
`;